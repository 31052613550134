import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import ImageWebp from 'src/components/ImageWebp'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import QR_CODE from '../../assets/images/qrcode-programa-de-pontos.png'
type BenefitsType = {
  isMobile: boolean;
}

const DollarMorePoints = ({ isMobile }: BenefitsType) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_04',
    section_name: 'Dólar comercial + pontos do Loop? Muito mais economia!',
    element_name: 'Quero Dólares',
    element_action: 'click button',
  })

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        formName='Formulario abertura de contas'
        dataLayer={dataLayer}
        qrBaixeApp={QR_CODE}
        asFilledLnk='https://inter-co.onelink.me/Qyu7/doxs5phy'
      />
    </Modal>
  )

  return (
    <section id='dollar-account' className='py-5'>
      {openAccountRightForm}
      <div className='container py-md-4 py-lg-2'>
        <div className='row d-flex align-items-center justify-content-md-between'>
          <div className='col-12 col-md-5 col-xl-6 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-end'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/loop-pp-dobra/image.webp'
              altDescription='Super App Inter'
              arrayNumbers={[ 272, 272, 349, 501, 501 ]}
              arrayNumbersHeight={[ 272, 272, 349, 501, 501 ]}
              className='float-lg-right'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-md-first pr-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 mb-md-3 pr-0'>
              Dólar comercial + pontos do Loop? Muito mais economia!
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              Com o Loop você consegue transformar seus pontos em dólares na Global Account, a conta global do Inter.
            </p>
            {
              isMobile ? (
                <a
                  href='https://inter-co.onelink.me/Qyu7/doxs5phy'
                  target='_blank'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
                  rel='noreferrer'
                  title='Abrir Global Account'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Dólar comercial + pontos do Loop? Muito mais economia!',
                      element_name: 'Quero Dólares',
                      element_action: 'click button',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/doxs5phy',
                    })
                  }}
                >
                  Quero Dólares
                </a>
              ) : (
                <button
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
                  onClick={() => {
                    setIsModalOpen(true)
                    setDataLayer(dataLayer)
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Dólar comercial + pontos do Loop? Muito mais economia!',
                      element_name: 'Quero Dólares',
                      element_action: 'click button',
                    })
                  }}
                >
                  Quero Dólares
                </button>
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default DollarMorePoints
