import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import pageContext from './pageContext.json'
import qrCode from './assets/images/lpInterLoopQrCode.png'

import Hero from './sections/hero/_index'
import Benefits from './sections/benefits/_index'
import Travel from './sections/travel/_index'
import DollarMorePoints from './sections/dollar-more-points/_index'
import Bussiness from './sections/bussiness/_index'
import Points from './sections/points/_index'
import Acumulation from './sections/acumulation/_index'
import Faq from './sections/faq/_index'

import { Wrapper } from './style'

const ProgramaDePontos = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(200)

  const domReady = useDomReady()

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Abra sua conta digital e comece a acumular pontos com o cartão de crédito Inter'
        subtitle='Abra a câmera do celular e aponte para o <strong>QR Code</strong> abaixo para baixar o app.'
        qrCode={qrCode}
        className='text-center'
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  return (
    <Wrapper>
      {modal}
      <Layout pageContext={pageContext}>
        <Hero setIsOpen={setIsOpen} />
        <Benefits />
        <Travel setIsOpen={setIsOpen} />
        <DollarMorePoints isMobile={isMobile} />
        <Points setIsOpen={setIsOpen} />
        <Acumulation />
        <Bussiness />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ProgramaDePontos
